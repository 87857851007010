<template>
  <base-card>
    <v-card-title> Add Company </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-row>
          <v-col>
            <h6>Basic Details</h6>
            <v-text-field
              label="Name"
              v-model="newCompanyObject.name"
              :rules="[(x) => !!x || 'Name is required']"
              required
            ></v-text-field>
            <v-text-field
              label="Website"
              :messages="isAddingPax ? paxWebsiteMessage : ''"
              :error="isAddingPax && isPaxWebsiteOk"
              v-model="newCompanyObject.website"
              :rules="[
                (x) => !!x || 'Website is required',
                (x) => (!!x && x.includes('http')) || 'Must start with Http',
              ]"
              required
            >
            </v-text-field>
            <v-text-field
              label="Phone Number"
              v-model="newCompanyObject.phoneNumber"
              :rules="[(x) => !!x || 'Phone Number is required']"
              required
            ></v-text-field>
            <h6>External Services</h6>
            <v-checkbox
              v-model="isAddingColin"
              label="Create In Colin"
            ></v-checkbox>
            <v-expand-transition>
              <div v-show="isAddingColin" class="pl-4">
                <v-text-field
                  label="Colin Name"
                  v-model="colinName"
                  :rules="
                    isAddingColin
                      ? [(x) => !!x || 'Colin Name is required']
                      : []
                  "
                  required
                >
                </v-text-field>
              </div>
            </v-expand-transition>
            <v-checkbox
              v-model="isAddingHudu"
              label="Create In Hudu"
            ></v-checkbox>
            <v-expand-transition>
              <div v-show="isAddingHudu" class="pl-4">
                <v-text-field
                  :messages="huduMessage"
                  label="Hudu Name"
                  v-model="huduName"
                  :error="!isHuduNameOk"
                  :rules="
                    isAddingHudu ? [(x) => !!x || 'Hudu Name is required'] : []
                  "
                  required
                >
                </v-text-field>
              </div>
            </v-expand-transition>
            <v-checkbox
              v-model="isAddingNinja"
              label="Create In Ninja"
            ></v-checkbox>
            <v-expand-transition>
              <v-text-field
                v-show="isAddingNinja"
                label="Ninja Name"
                v-model="ninjaName"
                class="pl-4"
                required
                :rules="
                  isAddingNinja ? [(x) => !!x || 'Ninja Name is required'] : []
                "
              ></v-text-field>
            </v-expand-transition>
            <v-checkbox
              v-model="isAddingPax"
              label="Create In Pax8"
            ></v-checkbox>
            <v-expand-transition>
              <div class="pl-4" v-show="isAddingPax">
                <v-text-field
                  label="Pax8 Name"
                  v-model="paxName"
                  class="pl-4"
                  required
                  :rules="
                    isAddingPax ? [(x) => !!x || 'Pax8 Name is required'] : []
                  "
                >
                </v-text-field>
              </div>
            </v-expand-transition>
            <v-checkbox
              label="Create In Xero"
              v-model="isAddingXero"
            ></v-checkbox>
            <v-expand-transition>
              <div class="pl-4" v-show="isAddingXero">
                <trev-xero-tenant-select
                  :required="true"
                  :rules="
                    isAddingXero
                      ? [(x) => !!x || 'Xero Tenant Is Required']
                      : []
                  "
                  v-model="newXeroCompany.tenantId"
                >
                </trev-xero-tenant-select>
                <v-text-field
                  label="First Name"
                  v-model="newXeroCompany.firstName"
                  :rules="
                    isAddingXero
                      ? [(x) => !!x || 'Xero First Name is Required']
                      : []
                  "
                ></v-text-field>
                <v-text-field
                  label="Last Name"
                  v-model="newXeroCompany.lastName"
                  :rules="
                    isAddingXero
                      ? [(x) => !!x || 'Xero Last Name is Required']
                      : []
                  "
                ></v-text-field>
                <v-text-field
                  label="Email Address"
                  v-model="newXeroCompany.emailAddress"
                  :rules="
                    isAddingXero
                      ? [(x) => !!x || 'Xero Email Address is Required']
                      : []
                  "
                ></v-text-field>
              </div>
            </v-expand-transition>
          </v-col>
          <v-col>
            <h6>Address Details</h6>
            <vuetify-google-autocomplete
              id="map"
              placeholder="Search Address..."
              types=""
              browserAutocomplete="false"
              v-on:placechanged="getAddressData"
            >
            </vuetify-google-autocomplete>
            <v-text-field
              label="First Line"
              v-model="newCompanyObject.firstLine"
              required
              :rules="
                isAddingPax ? [(x) => !!x || 'First Line is required'] : []
              "
            >
            </v-text-field>
            <v-text-field
              label="Second Line"
              v-model="newCompanyObject.secondLine"
              required
              :rules="
                isAddingPax ? [(x) => !!x || 'Second Line is required'] : []
              "
            >
            </v-text-field>
            <v-text-field
              label="Town"
              v-model="newCompanyObject.town"
              required
              :rules="isAddingPax ? [(x) => !!x || 'Town is required'] : []"
            >
            </v-text-field>
            <v-text-field
              label="County"
              v-model="newCompanyObject.county"
              required
              :rules="
                isAddingPax
                  ? [(x) => (x && isAddingPax) || 'County is required']
                  : []
              "
            >
            </v-text-field>
            <v-text-field
              label="Post Code"
              v-model="newCompanyObject.postCode"
              required
              :rules="
                isAddingPax
                  ? [(x) => (x && isAddingPax) || 'Post Code is required']
                  : []
              "
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-btn color="primary" class="mr-4" @click="addCompany">
          Add Company
        </v-btn>
      </v-form>
    </v-card-text>
    <v-snackbar v-model="showSnackBar" :timeout="1500" :color="snackColor">
      {{ snackBarMessage }}
    </v-snackbar>
    <v-dialog
      v-model="isShowingNewAddress"
      max-width="65vh"
      @click:outside="cancelCopyAddress"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title> Found Address Details </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-list>
                  <v-list-item two-line v-if="newAddressDetails.name">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="newAddressDetails.isName"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ newCompanyObject.name }}
                        <v-icon v-if="newCompanyObject.name"
                          >mdi-arrow-right-bold-outline</v-icon
                        ><v-icon v-else> mdi-asterisk</v-icon>
                        {{ newAddressDetails.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle> Name </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line v-if="newAddressDetails.phoneNumber">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="newAddressDetails.isPhoneNumber"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ newCompanyObject.phoneNumber }}
                        <v-icon v-if="newCompanyObject.phoneNumber"
                          >mdi-arrow-right-bold-outline</v-icon
                        ><v-icon v-else> mdi-asterisk</v-icon>
                        {{ newAddressDetails.phoneNumber }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Phone Number
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line v-if="newAddressDetails.website">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="newAddressDetails.isWebSite"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ newCompanyObject.website }}
                        <v-icon v-if="newCompanyObject.website"
                          >mdi-arrow-right-bold-outline</v-icon
                        ><v-icon v-else> mdi-asterisk</v-icon>
                        {{ newAddressDetails.website }}
                      </v-list-item-title>
                      <v-list-item-subtitle> Website </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line v-if="newAddressDetails.firstLine">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="newAddressDetails.isFirstLine"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ newCompanyObject.firstLine }}
                        <v-icon v-if="newCompanyObject.firstLine"
                          >mdi-arrow-right-bold-outline</v-icon
                        ><v-icon v-else> mdi-asterisk</v-icon>
                        {{ newAddressDetails.firstLine }}
                      </v-list-item-title>
                      <v-list-item-subtitle> First Line </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line v-if="newAddressDetails.secondLine">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="newAddressDetails.isSecondLine"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ newCompanyObject.secondLine }}
                        <v-icon v-if="newCompanyObject.secondLine"
                          >mdi-arrow-right-bold-outline</v-icon
                        ><v-icon v-else> mdi-asterisk</v-icon>
                        {{ newAddressDetails.secondLine }}
                      </v-list-item-title>
                      <v-list-item-subtitle> Second Line </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line v-if="newAddressDetails.town">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="newAddressDetails.isTown"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ newCompanyObject.town }}
                        <v-icon v-if="newCompanyObject.town"
                          >mdi-arrow-right-bold-outline</v-icon
                        ><v-icon v-else> mdi-asterisk</v-icon>
                        {{ newAddressDetails.town }}
                      </v-list-item-title>
                      <v-list-item-subtitle> Town </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line v-if="newAddressDetails.county">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="newAddressDetails.isCounty"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ newCompanyObject.county }}
                        <v-icon v-if="newCompanyObject.county"
                          >mdi-arrow-right-bold-outline</v-icon
                        ><v-icon v-else> mdi-asterisk</v-icon>
                        {{ newAddressDetails.county }}
                      </v-list-item-title>
                      <v-list-item-subtitle> County </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line v-if="newAddressDetails.postcode">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="newAddressDetails.isPostCode"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ newCompanyObject.postcode }}
                        <v-icon v-if="newCompanyObject.postcode"
                          >mdi-arrow-right-bold-outline</v-icon
                        ><v-icon v-else> mdi-asterisk</v-icon>
                        {{ newAddressDetails.postcode }}
                      </v-list-item-title>
                      <v-list-item-subtitle> Post Code </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="copyAddressDetails">
            <v-icon class="mr-2"> mdi-copy </v-icon>
            Copy Details
          </v-btn>
          <v-btn text @click="cancelCopyAddress"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </base-card>
</template>

<script>
import trevFormCreator from "../../../../components/forms/trev-form-creator.vue";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import TrevXeroTenantSelect from "../../../../components/forms/trev-xero-tenant-select.vue";

export default {
  components: {
    trevFormCreator,
    VuetifyGoogleAutocomplete,
    TrevXeroTenantSelect,
  },
  watch: {
    "newCompanyObject.name"() {
      this.huduName = this.newCompanyObject.name;
      this.ninjaName = this.newCompanyObject.name;
      this.paxName = this.newCompanyObject.name;
      this.colinName = this.newCompanyObject.name;
    },
    huduName() {
      this.getHuduCompanyByName(this.huduName);
    },
    "newCompanyObject.website"() {
      this.isPaxWebsiteDirty = true;
      this.checkPaxDomain(this.newCompanyObject.website);
    },
  },
  methods: {
    cancelCopyAddress() {
      this.newAddressDetails = {};
      this.isShowingNewAddress = false;
    },
    copyAddressDetails() {
      if (this.newAddressDetails.isName) {
        this.newCompanyObject.name = this.newAddressDetails.name;
      }

      if (this.newAddressDetails.isWebSite) {
        this.newCompanyObject.website = this.newAddressDetails.website;
      }

      if (this.newAddressDetails.isPhoneNumber) {
        this.newCompanyObject.phoneNumber = this.newAddressDetails.phoneNumber;
      }

      if (this.newAddressDetails.isFirstLine) {
        this.newCompanyObject.firstLine = this.newAddressDetails.firstLine;
      }

      if (this.newAddressDetails.isSecondLine) {
        this.newCompanyObject.secondLine = this.newAddressDetails.secondLine;
      }

      if (this.newAddressDetails.isTown) {
        this.newCompanyObject.town = this.newAddressDetails.town;
      }

      if (this.newAddressDetails.isCounty) {
        this.newCompanyObject.county = this.newAddressDetails.county;
      }

      if (this.newAddressDetails.isPostCode) {
        this.newCompanyObject.postCode = this.newAddressDetails.postcode;
      }

      this.cancelCopyAddress();
    },
    getAddressData(obj, placeObj) {
      this.newAddressDetails = {
        isFirstLine: true,
        isSecondLine: true,
        isTown: true,
        isCounty: true,
        isPostCode: true,
        isName: true,
        isPhoneNumber: true,
        isWebSite: true,
        firstLine: "",
        secondLine: "",
        town: "",
        county: "",
        postcode: "",
        name: "",
        phoneNumber: "",
        website: "",
      };

      if (placeObj.name) {
        this.newAddressDetails.name = placeObj.name;
      }

      if (placeObj.formatted_phone_number) {
        this.newAddressDetails.phoneNumber = placeObj.formatted_phone_number;
      }

      if (placeObj.website) {
        this.newAddressDetails.website = placeObj.website;
      }

      if (
        placeObj.address_components &&
        placeObj.address_components.length > 0
      ) {
        var a = placeObj.address_components;

        if (a.some((s) => s.types.some((x) => x == "route"))) {
          this.newAddressDetails.firstLine = a.find((x) =>
            x.types.some((s) => s == "route")
          ).long_name;
        }

        if (a.some((s) => s.types.some((x) => x == "sublocality_level_1"))) {
          this.newAddressDetails.secondLine = a.find((x) =>
            x.types.some((s) => s == "sublocality_level_1")
          ).long_name;
        }

        if (a.some((s) => s.types.some((x) => x == "portal_town"))) {
          this.newAddressDetails.town = a.find((x) =>
            x.types.some((s) => s == "portal_town")
          ).long_name;
        }

        if (
          a.some((s) => s.types.some((x) => x == "administrative_area_level_2"))
        ) {
          this.newAddressDetails.county = a.find((x) =>
            x.types.some((s) => s == "administrative_area_level_2")
          ).long_name;
        }

        if (a.some((s) => s.types.some((x) => x == "postal_code"))) {
          this.newAddressDetails.postcode = a.find((x) =>
            x.types.some((s) => s == "postal_code")
          ).long_name;
        }
      }

      this.isShowingNewAddress = true;
    },
    async checkPaxDomain(input) {
      this.isSearchingPax = true;
      this.isPaxWebsiteOk = false;

      var paxResp = await this.$courier.PaxExternal.getData(1, 100, input);
      this.isSearchingPax = false;

      if (paxResp) {
        if (paxResp.payload) {
          //chekc the payload for any with website

          if (
            paxResp.payload.some(
              (x) => x.website == this.newCompanyObject.website
            )
          ) {
            var fComp = paxResp.payload.filter(
              (x) => x.website == this.newCompanyObject.website
            )[0];
            this.isPaxWebsiteOk = false;
            this.paxWebsiteMessage =
              "Domain already exists with another company: " + fComp.name;
          } else {
            this.paxWebsiteMessage = "Website is Ok.";
          }
        }
      }
    },
    async getHuduCompanyByName(input) {
      this.isSearchingHudu = true;
      this.isHuduNameOk = false;
      var huduCompanies = await this.$courier.Hudu.getData(1, 100, input);
      this.isSearchingHudu = false;
      if (huduCompanies && huduCompanies.length > 0) {
        if (huduCompanies.length > 1) {
          this.huduMessage =
            "Please Choose Another Hudu Company Name " +
            huduCompanies.length +
            " matches";
        } else {
          this.huduMessage =
            "Please Choose Another Hudu Company Name, " +
            huduCompanies[0].name +
            " already exists";
        }
      } else {
        this.isHuduNameOk = true;
        this.huduMessage = "Hudu Name is ok.";
      }
    },
    async addCompany() {
      //this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        var newCompany = await this.$courier.Companies.add(
          this.newCompanyObject
        );

        if (
          this.isAddingHudu ||
          this.isAddingNinja ||
          this.isAddingPax ||
          this.isAddingXero ||
          this.isAddingColin
        ) {
          this.snackColor = "warning";
          this.showSnackBar = true;
          this.snackBarMessage = "Adding External Services";
        } else {
          this.snackColor = "success";
          this.showSnackBar = true;
          this.snackBarMessage = "Successfully Added Company";

          this.$router.push({
            name: "Company Dashboard",
            params: { companyId: newCompany.id },
          });
        }

        //do the creating in hudu or ninja etc

        if (this.isAddingHudu) {
          this.$courier.Hudu.add(
            {
              name: this.huduName,
            },
            false
          ).then((huduCompany) => {
            newCompany.huduId = huduCompany.id;
            newCompany.huduSlug = huduCompany.slug;
            newCompany.huduUrl = huduCompany.full_url;
            newCompany.huduPasswordUrl = huduCompany.passwords_url;
            newCompany.huduKbUrl = huduCompany.knowledge_base_url;

            this.$courier.Companies.withId(newCompany.id).updateById(
              newCompany.id,
              newCompany
            );
          });
        }

        if (this.isAddingPax) {
          this.$courier.PaxExternal.add(
            {
              name: this.paxName,
              website: this.newCompanyObject.website,
              selfServiceAllowed: false,
              billOnBehalfOfEnabled: true,
              orderApprovalRequired: false,
              phone: this.newCompanyObject.phoneNumber,
              status: "Active",
              address: {
                street: this.newCompanyObject.firstLine,
                city: this.newCompanyObject.town,
                postalCode: this.newCompanyObject.postCode,
                country: "UK",
                stateOrProvince: this.newCompanyObject.county,
              },
            },
            false
          ).then((paxCompany) => {
            //now add the links

            this.$courier.Companies.withId(newCompany.id).PaxLinks.add({
              pax8Id: paxCompany.id,
              pax8Name: paxCompany.name,
            });
          });
        }

        if (this.isAddingNinja) {
          await this.$courier.NinjaExternal.add(
            {
              name: this.ninjaName,
            },
            false
          ).then((ninjaCompany) => {
            this.$courier.Companies.withId(newCompany.id).NinjaLinks.add({
              ninjaId: ninjaCompany.id,
              name: ninjaCompany.name,
            });
          });
        }

        if (this.isAddingXero) {
          var tenantId = this.newXeroCompany.tenantId;
          delete this.newXeroCompany.tenantId;

          try {
            this.newXeroCompany.name = this.newCompanyObject.name;
            this.newXeroCompany.landLine = this.newCompanyObject.phoneNumber;

            this.$courier.XeroContactExternal.add(this.newXeroCompany, null, [
              { key: "tenantId", value: tenantId },
            ]).then((newContact) => {
              this.$courier.Companies.withId(newCompany.id).XeroLinks.add({
                xeroCustomerId: newContact.ContactID,
                xeroCustomerName: newContact.Name,
                xeroTenantId: tenantId,
              });
            });
          } catch (error) {
            console.log(error);
          }
        }

        //colin shit

        if(this.isAddingColin){
          console.log('anus')
          var colinComp = {
            companyName: this.colinName,
            ownerId: 1,
            active: 1,
            website: this.newCompanyObject.website,
            phone: this.newCompanyObject.phoneNumber,
            accountType: 2,
            status: 2
          };

          var addedColinComp = await this.$courier.ColinCompanies.add(colinComp);

          var colinAddress = {
            "_1stLine": this.newCompanyObject.firstLine,
            "_2ndLine": this.newCompanyObject.secondLine,
            town: this.newCompanyObject.town,
            county: this.newCompanyObject.county,
            postCode: this.newCompanyObject.postCode,
            type: 'Company',
            typeId: addedColinComp.id,
            active: 1
          };

          this.$courier.ColinAddresses.add(colinAddress);
        }

        this.$router.push({
          name: "Company Dashboard",
          params: { companyId: newCompany.id },
        });
      }
    },
  },
  data: () => ({
    newXeroCompany: {
      firstName: "",
      lastName: "",
      tenantId: "",
    },
    isAddingXero: false,
    isFormValid: false,
    paxOptions: {
      billOnBehalfOfEnabled: true,
      selfServiceAllowed: false,
      orderApprovalRequired: true,
    },
    isSearchingPax: false,
    isPaxWebsiteOk: true,
    isPaxWebsiteDirty: false,
    paxWebsiteMessage: "",
    isSearchingHudu: false,
    isHuduNameOk: false,
    huduMessage: "",
    snackColor: "success",
    showSnackBar: false,
    snackBarMessage: "",
    isAddingHudu: false,
    isAddingNinja: false,
    isAddingPax: false,
    isAddingColin: false,
    colinName: "",
    huduName: "",
    ninjaName: "",
    paxName: "",
    isShowingNewAddress: false,
    newAddressDetails: {},
    newCompanyForm: [
      {
        type: "text",
        label: "Name",
        name: "name",
      },
      {
        type: "text",
        label: "Website",
        name: "website",
      },
      {
        type: "text",
        label: "Support",
        name: "supportText",
      },
      {
        type: "text",
        label: "Zoho Id",
        name: "zohoId",
      },
    ],
    newCompanyObject: {
      name: "",
      website: "",
      phoneNumber: "",
      firstLine: "",
      secondLine: "",
      town: "",
      county: "",
      postCode: "",
    },
  }),
};
</script>

<style></style>
