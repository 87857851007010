<template>
  <div>
      form creator
  </div>
</template>

<script>
export default {
    props: {
        formObject: {
            type: Array,
            default: () => []            
        },
        model: {
            type: Object,
            default: () => {}
        }
    }
}
</script>

<style>

</style>