<template>
  <v-select
    label="Xero Tenant"
    v-model="selectedTenantId"
    @change="$emit('input', selectedTenantId)"
    :items="tenants"
    :rules="rules"
    :required="required"
    item-text="organisationName"
    item-value="tenantId"
  ></v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.getTenants();
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if(this.tenants.length === 0){
          this.getTenants();
        }
        this.selectedTenantId = newValue;
      },
    },
  },
  methods: {
    getTenants() {
      var allProfiles = this.$store.getters.getXeroProfiles;
      this.tenants = allProfiles;
    },
  },
  data() {
    return {
      tenants: [],
      selectedTenantId: "",
    };
  },
};
</script>

<style>
</style>